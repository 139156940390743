import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Aguarde, está chegando o seu novo e-commerce de semijoias!
        </p>
      </header>
    </div>
  );
}

export default App;
